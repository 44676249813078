/* eslint-disable react/forbid-prop-types */
import lodashSet from 'lodash/set';
import lodashGet from 'lodash/get';
// import shaka from 'shaka-player';
// import shaka from 'shaka-player-legacy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import muxjs from 'mux.js';
import { checkWindowExist, DeviceTypeUtil, raiseEvent } from '@airtel-tv/utils';
import { DRM_LIST, STREAM_TYPES, OS_LIST } from '@airtel-tv/constants/BrowserConst';
import {
    PLAYER_CONFIG,
    PLAYER_TYPES,
    PLAYER_EVENTS_LIST,
    DEFAULT_CP_CONTROL_CONFIG,
    DEFAULT_CONTENT_DESCRIPTOR_CONFIG,
} from '@airtel-tv/constants/PlayerConsts';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import {
    adBreakEndEvent, adCompleteEvent, adLoadEvent, adStartEvent, adClickEvent, adPauseEvent, adResumeEvent,
    adInitEvent, adStartedEvent, adErrorEvent, adEndedEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
import getVTTContent, { default as toWebVTT } from '@airtel-tv/utils/SRT2VTT';
import { PROGRAM_TYPES, AD_TYPE } from '@airtel-tv/constants/GlobalConst';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { updateLionsgateCPTrailerURL } from '@airtel-tv/utils/GlobalUtil';
import { AD_SOURCES, AD_TAG_TYPE, checkSdkAdsAllowed } from '@airtel-tv/utils/AdSdkUtil';
import { showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import getPlayerControls from '../../factories/PlayerControlsFactory';
import getPlayerEvents from '../../factories/PlayerEventsFactory';
import { AbstractPlayer } from '../abstract-player/AbstractPlayer';
import {
    getShowPreRollAds, adsPlaying, getShowPostRollAds, getAdPausedStatus, setSdkAdsPlaying,
} from '../../actions/PlaybackActions';
import { isWeb as isWebBrowser } from '../../util/PlaybackUtility';
// import LanguageProvider from '../../../../web/src/providers/LanguageProvider';
import LanguageCodesProvider from '../../../../web/src/providers/LanguageCodesProvider';
import { toggleForwardButton } from '../../../../tv/src/modules/notify/NotifyActions';
import { updateKeyboardControlsAction } from '../../../../web/src/modules/notify/NotifyActions';

const LANGUAGES_CODES = LanguageCodesProvider();

let shaka = null;

class ShakaPlayerComponent extends AbstractPlayer {
    constructor(props) {
        super(props, PLAYER_TYPES.SHAKA_PLAYER);
        this.isWeb = isWebBrowser();
        this.PlayerCreated = false;
        window.muxjs = muxjs;
    }

    basicRequestInterceptor = (type, request) => {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
            this.playerEvents.emit(PLAYER_EVENTS_LIST.LICENSE_API_INIT);
        }
        // if(type === shaka.net.NetworkingEngine.RequestType.MANIFEST){
        //     this.playerEvents.emit(PLAYER_EVENTS_LIST.MANIFEST_API_INIT);
        // }
    };

    interceptRequest = (type, request) => {
        const { playbackConfig: { playbackUrlCookies, isMsp } } = this.props;
        const uri = new URL(request.uris[0]);
        const { pathname = '' } = uri;
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
            const {
                playbackConfig: {
                    drm: { handleLicenseRequest },
                },
            } = this.props;
            if (handleLicenseRequest) {
                handleLicenseRequest(request);
            }
        }
        else if (isMsp && (type === shaka.net.NetworkingEngine.RequestType.SEGMENT || type === shaka.net.NetworkingEngine.RequestType.INIT_SEGMENT || pathname.endsWith('.m3u8') || pathname.endsWith('.ts') || pathname.endsWith('.aac'))) {
            request.allowCrossSiteCredentials = true;
            const playbackUrlSplitted = this.props.playbackConfig.playbackUrl.split('?');
            uri.search = uri.search ? `${uri.search}&${playbackUrlSplitted[1]}` : `?${playbackUrlSplitted[1]}`;
            uri.search = new URLSearchParams(Object.fromEntries(new URLSearchParams(uri.search))).toString();
            uri.search = decodeURIComponent(uri.search);
            request.uris[0] = uri.href;
        }
        else if (playbackUrlCookies) {
            request.allowCrossSiteCredentials = true;
        }
    };

    interceptResponse = (type, response) => {
        if (type === shaka.net.NetworkingEngine.RequestType.MANIFEST) {
            this.playerFunctions.setManifestDownloadTime(response.timeMs);
        }

        if ((type === shaka.net.NetworkingEngine.RequestType.SEGMENT) && (response.uri.indexOf('.srt') > -1)) {
            response.data = getVTTContent(response.data, 'arrayBuffer');
        }

        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
            const {
                playbackConfig: {
                    drm: { handleLicenseResponse },
                },
            } = this.props;
            this.playerFunctions.setLicenseDownoadTime(response.timeMs);
            this.playerEvents.emit(PLAYER_EVENTS_LIST.LICENSE_API_RESPONSE);
            handleLicenseResponse && handleLicenseResponse(response);
        }
    };

    addSubtitleDelay = () => {
        const {
            playbackDetails,
            playbackControlMaster,
        } = this.props;
        const cpName = lodashGet(playbackDetails, 'cp', '');
        const { subtitleOffset = {} } = playbackControlMaster;
        const cpSubtitleDelayConfig = subtitleOffset[cpName];
        let { subtitleDelta } = playbackDetails;
        if (!(cpSubtitleDelayConfig && cpSubtitleDelayConfig.enabled && subtitleDelta)) {
            return;
        }
        const { offsetType = 'negative', listenerDelay = 1000, customOffset } = cpSubtitleDelayConfig;
        this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.SUBTITLE_ADDED, () => {
            let delayOffset;
            if (customOffset) { // Only add customOffset to cms if you want to overwrite value coming from backend
                subtitleDelta = customOffset;
            }

            if (offsetType === 'negative') {
                delayOffset = -(subtitleDelta / 1000);
            }
            else {
                delayOffset = (subtitleDelta / 1000);
            }
            setTimeout(() => {
                if (!this.videoNode) {
                    return;
                }
                Object.keys(this.videoNode.textTracks || []).map((trackId) => {
                    if (!this.videoNode) {
                        return;
                    }
                    const { cues } = this.videoNode.textTracks[trackId];
                    if (cues && cues.length) {
                        [
                            ...cues,
                        ].map((item) => {
                            this.videoNode?.textTracks[trackId]?.removeCue(item);
                            const startTime = item?.startTime + delayOffset;
                            const endTime = item?.endTime + delayOffset;
                            this.videoNode.textTracks[trackId].addCue(new VTTCue(startTime, endTime, item.text));
                        });
                    }
                });
            }, listenerDelay);
        });
    };

    async initPlayer() {
        if (this.player) {
            this.player = null;
        }
        // destructors props to use locals
        const videoTag = this.videoNode;
        if (videoTag) {
            this.player = new shaka.Player();
        }
        const {
            playbackConfig,
            playbackConfig: { useLegacyShaka = false } = {},
            playbackDetails,
            playbackDetails: { cpXstreamLangCodeMap = [], cp = '' } = {},
            dispatchGetAdsPlaying,
            bufferingGoal = PLAYER_CONFIG.GOAL_BUFFER_LENGTH,
            updateKeyboardControlsActionDispatch,
            playbackControlMaster,
            isTrailer = false,
            CP_CONTROL_CONFIG,
            adConfig,
        } = this.props;
        let { contentDetails } = this.props;

        if (!DeviceTypeUtil.isWeb() && lodashGet(playbackDetails, 'ads.meta.type', '') && playbackDetails.ads.meta.type.includes('POST')) {
            this.props.dispatchShowPostRollAds({ postRollAds: true });
        }
        if (this.player) {
            if (this.playerFunctions) {
                // unload for new content and wait for unload to finish before loading
                await this.playerFunctions.dispose();
                let videoArr = document.getElementsByTagName('video');
                videoArr = [
                    ...videoArr,
                ];
                videoArr.map(item => item?.remove());
                this.renewVideoTag();
            }
        }

        // Create a Player instance.
        // const videoTag = this.videoNode;
        // if (videoTag) {
        //     this.player = new shaka.Player();
        // }

        const {
            drm,
            playbackUrl,
            isDrm,
            streamType,
            playbackUrlCookies,
            videoResolutionLimiter,
            isMsp,
            daiAssetKey,
        } = playbackConfig || {};

        const {
            licenseUri, handleLicenseRequest, handleLicenseResponse,
        } = drm || {};
        const preferredAudioLanguage = lodashGet(playbackDetails, 'plg', '');
        const {
            streamingParams = {}, streamingRetryParams = {}, manifestParams = {}, manifestRetryParams = {}, drmParams = {}, drmRetryParams = {},
            playerInitialMaxHeight = 500,
        } = playbackControlMaster || {};
        const { playerStreamingConfig = {} } = this.props;
        const playerConfigInitialHeight = CP_CONTROL_CONFIG?.[contentDetails?.cpId]?.abrInitialMaxHeight;
        const abrHeightRestriction = playerConfigInitialHeight || playerInitialMaxHeight;
        // start create config for video player
        let shakaOptions = {
            preferredAudioLanguage,
            streaming: {
                ignoreTextStreamFailures: true,
                bufferingGoal: 60,
                rebufferingGoal: 20,
                retryParameters: {
                    ...streamingRetryParams,
                },
                ...streamingParams,
                ...playerStreamingConfig,
            },
            manifest: {
                retryParameters: {
                    maxAttempts: 5, // the maximum number of requests before we fail
                    ...manifestRetryParams,
                },
                ...manifestParams,
            },
            abr: {
                restrictions: { maxHeight: abrHeightRestriction },
                enabled: true,
            },

        };

        if (isDrm) {
            shakaOptions = {
                ...shakaOptions,
                preferredAudioLanguage: lodashGet(playbackDetails, 'plg', ''),
                drm: {
                    servers: {
                        [DRM_LIST.WIDEVINE]: licenseUri,
                    },
                    retryParameters: {
                        maxAttempts: 2, // the maximum number of requests before we fail
                        ...drmRetryParams,
                    },
                    ...drmParams,
                },
            };
        }


        if (videoResolutionLimiter) {
            const {
                MAX_WIDTH,
                MAX_HEIGHT,
            } = videoResolutionLimiter;
            // source: https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.Restrictions
            if (MAX_WIDTH) {
                lodashSet(shakaOptions, 'abr.restrictions.maxWidth', MAX_WIDTH);
            }
            if (MAX_HEIGHT) {
                lodashSet(shakaOptions, 'abr.restrictions.maxHeight', MAX_HEIGHT);
            }
        }

        // if (this.player && this.playerFunctions) {
        // // unload for new content and wait for unload to finish before loading
        //     await this.playerFunctions.dispose();
        //     this.player = null;
        //     let videoArr = document.getElementsByTagName('video');
        //     videoArr = [
        //         ...videoArr,
        //     ];
        //     videoArr.map(item => item?.remove());
        //     this.renewVideoTag();
        // }

        // donot load player if playbackUrl is not available
        if (!playbackConfig.playbackUrl && this.controlsBarInstance) {
            this.controlsBarInstance.enableControlBar(); // enabling control bar so that when user click on play the we can call reload
            return;
        }
        if (videoTag) {
            // this.player = new shaka.Player();
            // window.player = this.player;

            this.playerFunctions = getPlayerControls({
                playerType: PLAYER_TYPES.SHAKA_PLAYER,
                player: this.player,
                videoTag,
                isLiveStream: streamType === STREAM_TYPES.LIVE,
                contentDetails,
            });
            this.playerEvents = getPlayerEvents({
                playerType: PLAYER_TYPES.SHAKA_PLAYER,
                player: this.player,
                videoTag,
                playerFunctions: this.playerFunctions,
            });
            this.playerFunctions.setPlayerVersion(shaka.Player.version);

            const { playerEventsInitialized, dispatchToggleForwardButton } = this.props;
            if (playerEventsInitialized) {
                await playerEventsInitialized(this.playerEvents, this.playerFunctions, useLegacyShaka);
            }

            await this.player.attach(videoTag);

            window.atvPlayer = videoTag;

            this?.player?.configure(shakaOptions);
            this.player.getNetworkingEngine().registerRequestFilter(this.basicRequestInterceptor);
            if ((handleLicenseRequest || playbackUrlCookies || isMsp)) {
                this.player.getNetworkingEngine()
                    .registerRequestFilter(this.interceptRequest);
            }
            this.player.getNetworkingEngine()
                .registerResponseFilter(this.interceptResponse);

            contentDetails = {
                ...contentDetails,
                cpXstreamLangCodeMap,
            };

            const adManager = this.player?.getAdManager && this.player.getAdManager();
            let adTagUrl = lodashGet(playbackDetails, 'ads.meta.url', '');
            const adXml = lodashGet(playbackDetails, 'ads.adData.ad', ''); // xml
            const _ad_TagUrl_ = browserStore.get('_ad_TagUrl_');
            if (_ad_TagUrl_) {
                console.log('adTag====>', _ad_TagUrl_);
                adTagUrl = _ad_TagUrl_;
            }
            // const adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/423477888/Airtel_xstreme_VOD_AOS&url=%5Breferrer_url%5D&description_url=%5Bdescription_url%5D&cmsid=2530476&env=vp&impl=s&correlator=1234&tfcd=0&npa=0&gdfp_req=1&output=vmap&sz=640x480&unviewed_position_start=1&vid=%5Bvid%5D';
            const sdkAdsAllowed = checkSdkAdsAllowed(contentDetails, adConfig);
            // First check if content has adsAllowed flag and we have corresponding cp in adconfig
            // if no then check if we have adtag in playback details
            if (this.isWeb && !sdkAdsAllowed && adManager && (adTagUrl || adXml) && !useLegacyShaka) {
                const contentName = lodashGet(playbackConfig, 'videoMeta.videoTitle', '');
                const isdrm = lodashGet(playbackConfig, 'isDrm', '');
                const contentId = lodashGet(playbackConfig, 'contentId', '');
                const cpName = lodashGet(playbackDetails, 'cp', '');
                const contentLanguage = lodashGet(playbackDetails, 'plg', '');
                const playbackContentType = lodashGet(playbackDetails, 'playbackType', '');

                const adsAnalyticsMeta = {
                    content_name: contentName,
                    app_type: ANALYTICS_ASSETS.WEB,
                    ad_type: ANALYTICS_ASSETS.PRE_ROLL,
                    cp_name: cpName,
                    bitrate: ANALYTICS_ASSETS.NULL,
                    content_language: contentLanguage,
                    content_type: playbackContentType,
                    content_id: contentId,
                    is_drm_content: isdrm,
                    channel_id: contentId,
                };

                const nonSdkAdsAnalyticsMeta = {
                    content_id: contentId,
                    client_ad_source: AD_SOURCES.PARTNER,
                    client_ad_type: AD_TAG_TYPE.VMAP, // @NGTD: For MiniTv add a condition to send xml here
                };

                adManager.initClientSide(this.adContainer, this.videoNode);
                const adsRequest = new google.ima.AdsRequest();
                if (adXml) {
                    adsRequest.adsResponse = adXml;
                }
                else {
                    adsRequest.adTagUrl = adTagUrl;
                }
                adManager.requestClientSideAds(adsRequest);
                adManager.addEventListener(shaka.ads.AdManager.AD_STARTED, (e) => {
                    adStartEvent(adsAnalyticsMeta);
                    adStartedEvent(nonSdkAdsAnalyticsMeta);
                    this.setAdManager(e.ad);
                    const adsPlayingFlag = true;
                    const isClientSideAd = true;
                    this.adContainer.style['z-index'] = 99;
                    const { CLIENT_SIDE_AD = 'CLIENT_SIDE_AD' } = AD_TYPE;
                    const adType = CLIENT_SIDE_AD;
                    dispatchGetAdsPlaying({
                        adsPlayingFlag,
                        isClientSideAd,
                        adType,
                    });
                    updateKeyboardControlsActionDispatch({ enableKeyboardControls: false });
                });

                adManager.addEventListener(shaka.ads.AdManager.AD_LOADED, (e) => {
                    adLoadEvent(adsAnalyticsMeta);
                    adInitEvent(nonSdkAdsAnalyticsMeta);
                    // this.adContainer.style['z-index'] = 99;
                    // const ele = document.getElementsByTagName('video');
                    // ele[0].style.width = '100vw';
                    // ele[0].style.height = '100vh';
                    // ele[0].style['z-index'] = 99;
                });

                adManager.addEventListener(shaka.ads.AdManager.IMA_AD_MANAGER_LOADED, (e) => {
                    const { imaAdManager } = e;
                    imaAdManager.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, () => {
                        this.adContainer.style['z-index'] = 'auto';
                        const adsPlayingFlag = false;
                        const isClientSideAd = false;
                        const adType = '';
                        dispatchGetAdsPlaying({
                            adsPlayingFlag,
                            isClientSideAd,
                            adType,
                        });
                        updateKeyboardControlsActionDispatch({ enableKeyboardControls: true });
                        adBreakEndEvent(adsAnalyticsMeta);
                    });
                });

                // adManager.addEventListener(shaka.ads.AdManager.ALL_ADS_COMPLETED, (e) => {
                //     console.log("called")
                //     const adsPlayingFlag = false;
                //     const isClientSideAd = false;
                //     const adType = '';
                //     dispatchGetAdsPlaying({ adsPlayingFlag, isClientSideAd, adType });
                //     updateKeyboardControlsActionDispatch({ enableKeyboardControls: true });
                //     adBreakEndEvent(adsAnalyticsMeta);
                // })

                adManager.addEventListener(shaka.ads.AdManager.AD_PROGRESS, (e) => {
                    if (checkWindowExist()) {
                        if (window.isAppNotFocused) {
                            this.imaAdManager.pause();
                        }
                    }
                    const progressData = e.originalEvent.getAdData();
                    const timerElement = window.document.getElementById('ad-timer');
                    const {
                        duration, adPosition, totalAds, currentTime,
                    } = progressData;
                    if (!this.videoNode?.paused) {
                        this.videoNode?.pause();
                    }
                    const timeRemaining = Math.ceil(duration - currentTime);
                    let min = Math.floor(timeRemaining / 60);
                    let sec = timeRemaining % 60;
                    min = min < 10 ? `0${min}` : min;
                    sec = sec < 10 ? `0${sec}` : sec;
                    timerElement && (timerElement.innerHTML = `Ad ${adPosition} of ${totalAds} (${min}:${sec})`);
                });

                adManager.addEventListener(shaka.ads.AdManager.AD_RESUMED, (e) => {
                    raiseEvent(this.videoNode, PLAYER_EVENTS_LIST.AD_RESUME);
                    adResumeEvent(adsAnalyticsMeta);
                });
                adManager.addEventListener(shaka.ads.AdManager.AD_PAUSED, (e) => {
                    raiseEvent(this.videoNode, PLAYER_EVENTS_LIST.AD_PAUSE);
                    adPauseEvent(adsAnalyticsMeta);
                });
                adManager.addEventListener(shaka.ads.AdManager.AD_CLICKED, (e) => {
                    adClickEvent(adsAnalyticsMeta);
                });
                adManager.addEventListener(shaka.ads.AdManager.AD_COMPLETE, () => {
                    adEndedEvent(nonSdkAdsAnalyticsMeta);
                });
                adManager.addEventListener(shaka.ads.AdManager.AD_ERROR, () => {
                    adErrorEvent(nonSdkAdsAnalyticsMeta);
                });
            }

            let playbackAsset = updateLionsgateCPTrailerURL(playbackUrl, cp);
            if (daiAssetKey && false) {
                const adManager = this.player.getAdManager();
                const timerElement = window.document.getElementById('ad-timer');

                const contentName = lodashGet(playbackConfig, 'videoMeta.videoTitle', '');
                const isdrm = lodashGet(playbackConfig, 'isDrm', '');
                const contentId = lodashGet(playbackConfig, 'contentId', '');
                const cpName = lodashGet(playbackDetails, 'cp', '');
                const contentLanguage = lodashGet(playbackDetails, 'plg', '');
                const playbackContentType = lodashGet(playbackDetails, 'playbackType', '');

                const adsAnalyticsMeta = {
                    content_name: contentName,
                    app_type: ANALYTICS_ASSETS.WEB,
                    ad_type: ANALYTICS_ASSETS.MID_ROLL,
                    cp_name: cpName,
                    bitrate: ANALYTICS_ASSETS.NULL,
                    content_language: contentLanguage,
                    content_type: playbackContentType,
                    content_id: contentId,
                    is_drm_content: isdrm,
                    channel_id: contentId,
                };

                adManager.addEventListener(shaka.ads.AdManager.ADS_LOADED, (e) => {
                    adLoadEvent(adsAnalyticsMeta);
                });

                adManager.addEventListener(shaka.ads.AdManager.IMA_STREAM_MANAGER_LOADED, (e) => {
                    const { imaStreamManager } = e;

                    imaStreamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED, (e) => {
                        const adsPlayingFlag = true;
                        dispatchGetAdsPlaying({ adsPlayingFlag });
                    });

                    imaStreamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.STARTED, (e) => {
                    // console.log("started",e.getAd(),e.getAd().getDuration(), e.getAd().getAdPodInfo().getAdPosition(),e.getStreamData().progressData);
                    // console.log("ll","ad complete")

                        const ad = e.getAd();
                        const adId = ad.getAdId();
                        const adTitle = ad.getTitle();
                        const adStartAnalyticsMeta = {
                            content_name: contentName,
                            app_type: ANALYTICS_ASSETS.WEB,
                            ad_type: ANALYTICS_ASSETS.MID_ROLL,
                            cp_name: cpName,
                            bitrate: ANALYTICS_ASSETS.NULL,
                            ...(adId && { ad_id: adId }),
                            ...(adTitle && { ad_tilte: adTitle }),
                            content_language: contentLanguage,
                            content_type: playbackContentType,
                            content_id: contentId,
                            is_drm_content: isdrm,
                            channel_id: contentId,
                        };
                        adStartEvent(adStartAnalyticsMeta);
                    });

                    imaStreamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.COMPLETE, (e) => {
                    // console.log("ll","ima complete",e.getAd(), e.getStreamData());
                        adCompleteEvent(adsAnalyticsMeta);
                    });


                    imaStreamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.AD_PROGRESS, (e) => {
                    // console.log("ppppp", e.getStreamData(), e.getStreamData().adProgressData);
                        const progressData = e.getStreamData().adProgressData;
                        const {
                            duration, adPosition, totalAds, currentTime,
                        } = progressData;
                        const timeRemaining = Math.ceil(duration - currentTime);
                        const min = Math.floor(timeRemaining / 60);
                        const sec = timeRemaining % 60;
                        timerElement && (timerElement.innerHTML = `Ad ${adPosition} of ${totalAds} (${min}:${sec})`);
                    });

                    imaStreamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED, (e) => {
                    // console.log("lll--->ad ended",e);
                        const adsPlayingFlag = false;
                        dispatchGetAdsPlaying({ adsPlayingFlag });
                        adBreakEndEvent(adsAnalyticsMeta);
                    });
                });

                adManager.initServerSide(this.videoWrapperRef, this.videoNode);
                const streamRequest = new google.ima.dai.api.LiveStreamRequest();
                streamRequest.assetKey = daiAssetKey;
                // streamRequest.assetKey = '4FwpwmqhQuaWR5vZCFSy6w'
                // streamRequest.assetKey = 'PSzZMzAkSXCmlJOWDmRj8Q'
                // streamRequest.assetKey = 'c-rArva4ShKVIAkNfy6HUQ'
                // streamRequest.assetKey = '8cov9tHQRj26xm-Qz6uWzQ'
                playbackAsset = await adManager.requestServerSideStream(streamRequest);
            }
            this.playerEventsHandler();

            // sending player events to parent to subscribe

            this.playerLoaded = true;
            if (!playbackAsset) {
            // In case of error in playback api due to age consent, concurrent playback etc we don't want have
            // playurl and hence don't want playback to load thereby reducing 4000 error
                if (this.controlsBarInstance) {
                    this.controlsBarInstance.enableControlBar();
                }
                return;
            }
            this.player.load(playbackAsset)
                .then(() => {
                    if (playbackDetails?.subs && Object.keys(playbackDetails.subs).length) {
                        Object.keys(playbackDetails.subs).forEach((item) => {
                            if (useLegacyShaka) {
                                this.player?.addTextTrack(playbackDetails.subs[item], item, 'subtitle', 'text/vtt', '', LANGUAGES_CODES[item]);
                            }
                            else {
                                this.player?.addTextTrackAsync(playbackDetails.subs[item], item, 'subtitle', 'text/vtt', '', LANGUAGES_CODES[item]);
                            }
                        });
                    }
                    const isMultiPeriod = !!this.playerFunctions.getPeriodDuration(); // Content like adivae don't get the ads flag true but is singleperiod

                    if (useLegacyShaka && isMultiPeriod) {
                        let subtitleAdded = false;
                        this.playerFunctions.play();
                        this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, (playbackCurrentTime) => {
                            const { showForwardButton } = this.props;
                            const currentTime = Math.floor(playbackCurrentTime);
                            if (DeviceTypeUtil.isLG()) {
                                if (currentTime === 4) {
                                    this.playerFunctions.forward(1);
                                }
                                if (currentTime < 8 && showForwardButton) {
                                    dispatchToggleForwardButton(false);
                                }
                                else if (currentTime > 8 && !this.props.showForwardButton) {
                                    dispatchToggleForwardButton(true);
                                }
                            }
                            if (currentTime >= 5 && !subtitleAdded && playbackDetails.subs && Object.keys(playbackDetails.subs).length) {
                                subtitleAdded = true;
                                // const idForAudio = programType && programType === PROGRAM_TYPES.EPISODE ? seriesId : id;
                                // if (idForAudio !== '' && continueWatchingData?.audioSync && continueWatchingData?.audioSync[idForAudio]) {
                                //     console.log(continueWatchingData?.audioSync[idForAudio], 'called')
                                //     this.playerFunctions.changeAudio(continueWatchingData?.audioSync[idForAudio], true);
                                // } else {
                                // this.playerFunctions.changeAudio(preferredAudioLanguage, true);
                                // }
                                Object.keys(playbackDetails.subs).forEach((item) => {
                                    if (!playbackDetails.subs[item].endsWith('.srt')) {
                                        this.player.addTextTrack(playbackDetails.subs[item], item, 'subtitle', 'text/vtt', '', LANGUAGES_CODES[item]);
                                    }
                                });
                                this.playerEvents.emit(PLAYER_EVENTS_LIST.SUBTITLE_ADDED);
                            }
                        });
                    }
                    raiseEvent(this.videoNode, PLAYER_EVENTS_LIST.SUBTITLE_ADDED, '');
                    this.enableSubtitleDisplayer();
                    this.enableControlBar();
                    this.installKeyboardControls();
                })
                .catch((error) => {
                    // enabling control bar so that when user click on play the we can call reload
                    if (this.controlsBarInstance) {
                        this.controlsBarInstance.enableControlBar();
                    }
                    if (this.playerEvents) {
                        if (error.code == ERROR_CODES.SHAKA_1001) {
                            this.playerEvents.emit(PLAYER_EVENTS_LIST.ERROR_CDN_RETRY, error);
                            return;
                        }
                        this.playerEvents.emit(PLAYER_EVENTS_LIST.ERROR, error);
                    }
                    console.log('error', error);
                }); // onError is executed if the asynchronous load fails.
            this.addSubtitleDelay();
        }
    }

    async initConfig(reload) {
        const { playbackConfig: { useLegacyShaka = false } = {}, playbackDetails, deviceUtil: { isIOS } } = this.props;
        shaka = useLegacyShaka && playbackDetails.contentType !== PROGRAM_TYPES.TRAILER ? await import('shaka-player-legacy') : await import('shaka-player');
        window.atvPlayer = null;
        window.atvPlayerFirstPlay = false;

        // Check to see if the browser supports the basic APIs Shaka needs.
        shaka.polyfill.installAll();
        if (!shaka.Player.isBrowserSupported()) {
            const { browserNotSupported } = this.props;
            browserNotSupported({
                code: ERROR_CODES.SHAKA_BROWSER_NOT_SUPPORTED,
            });
        }
        else if (this.isWeb && !isIOS() && !this.isScopedWebview) {
            // if (this.isScopedWebview) {
            //     this.initPlayer();
            //     return;
            // }

            // Install built-in polyfills to patch browser incompatibilities.
            const videoRef = this.videoNode;
            if (this.videoNode) {
                this.videoNode.src = 'static/test.mp4';
            }
            /**
             * Attempts autoplay and handles success and failure cases.
             */
            // eslint-disable-next-line no-inner-declarations
            function checkAutoplaySupport() {
                // Test for autoplay support with our content player.
                if (videoRef) {
                    videoRef.opacity = 0;
                }
                const playPromise = videoRef?.play();
                if (playPromise !== undefined) {
                    // eslint-disable-next-line no-use-before-define
                    playPromise.then(onAutoplayWithSoundSuccess).catch(onAutoplayWithSoundFail);
                }
            }
            /**
         * Handles case where autoplay succeeded with sound.
         */
            const onAutoplayWithSoundSuccess = () => {
            // If we make it here, unmuted autoplay works.
                videoRef.pause();
                autoplayAllowed = true;
                autoplayRequiresMuted = false;
                // this.videoNode.src = '';
                this.videoNode.removeAttribute('src');

                // request for ads
                this.videoNode.opacity = 1;
                this.initPlayer();
            };

            /**
         * Handles case where autoplay fails with sound.
         */
            const onAutoplayWithSoundFail = (e) => {
            // Unmuted autoplay failed. Now try muted autoplay.
                // eslint-disable-next-line no-use-before-define
                checkMutedAutoplaySupport();
            };

            /**
         * Checks if video can autoplay while muted.
         */
            const checkMutedAutoplaySupport = () => {
                videoRef.volume = 0;
                videoRef.muted = true;
                const playPromise = videoRef.play();
                if (playPromise !== undefined) {
                    // eslint-disable-next-line no-use-before-define
                    playPromise.then(onMutedAutoplaySuccess).catch(onMutedAutoplayFail);
                }
            };

            /**
         * Handles case where autoplay succeeded while muted.
         */
            const onMutedAutoplaySuccess = () => {
                videoRef.volume = 0;
                videoRef.muted = false;
                if (this.videoNode) {
                    // this.videoNode.src = '';
                    this.videoNode.removeAttribute('src');
                    // request for ads
                    this.videoNode.opacity = 1;
                }
                this.initPlayer(reload);
            // videoRef.play();
            };

            /**
         * Handles case where autoplay failed while muted.
         */
            const onMutedAutoplayFail = (e) => {
            // Both muted and unmuted autoplay failed. Fall back to click to play.
                videoRef.volume = 1;
                videoRef.muted = false;
                if (this.videoNode) {
                    this.videoNode.removeAttribute('src');
                    // request for ads
                    this.videoNode.opacity = 1;
                }
                this.initPlayer(reload);
            };
            checkAutoplaySupport();// autoplayback support check only in web as tv will have a keypress in starting
        }
        else {
            this.initPlayer(reload);
        }
    }
}


ShakaPlayerComponent.propTypes = {
    browserNotSupported: PropTypes.func.isRequired,
    reload: PropTypes.func,
    playerEventsInitialized: PropTypes.func,
    ControlBar: PropTypes.func,
    playbackDetails: PropTypes.object,
    playbackConfig: PropTypes.shape({
        playbackUrlCookies: PropTypes.object,
        browser: PropTypes.string,
        browserVersion: PropTypes.number,
        os: PropTypes.string,
        enableAutoplay: PropTypes.bool,
        enableNativeControls: PropTypes.bool,
        isDrm: PropTypes.bool,
        contentId: PropTypes.string,
        playbackUrl: PropTypes.string,
        playbackType: PropTypes.string.isRequired,
        drm: PropTypes.shape({
            drmType: PropTypes.string,
            handleLicenseRequest: PropTypes.func,
            handleLicenseResponse: PropTypes.func,
            licenseUri: PropTypes.string,
            handleCertificateRequest: PropTypes.func, // for fairplay
            certificateUri: PropTypes.string, // for fairplay
        }),
    }),
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    playTrailer: PropTypes.bool,
    dispatchSdkAdsPlaying: PropTypes.bool.isRequired,

};

ShakaPlayerComponent.defaultProps = {
    reload: () => null,
    playerEventsInitialized: () => null,
    ControlBar: () => null,
    playbackDetails: {},
    playbackConfig: {
        playbackUrlCookies: null,
        browser: '',
        browserVersion: '',
        os: '',
        enableAutoplay: false,
        playbackUrl: '',
        enableNativeControls: false,
        isDrm: false,
        contentId: '',
        drm: {
            drmType: '',
            handleLicenseRequest: undefined, // if provided then player will call this funtion to get license instead of get license itself
            handleLicenseResponse: undefined,
            licenseUri: '',
            handleCertificateRequest: undefined, // for fairplay
            certificateUri: '', // for fairplay
            ticket: '',
        },
    },
};

const mapStateToProps = (state) => {
    const {
        appConfig,
        playbackDetails,
        notify,
        appConfig: { tagDetails },
        contentDetails: { playableId = '' },
    } = state;
    const {
        adsData = {}, playbackControlMaster = {}, persistBufferOnFirstplay = 8, CP_CONTROL_CONFIG = DEFAULT_CP_CONTROL_CONFIG, contentDescriptorConfig = DEFAULT_CONTENT_DESCRIPTOR_CONFIG, playback_soft_nudge_timer_list: playbackSoftNudgeTimerList,
    } = appConfig || {};
    const adsPlayingFlag = lodashGet(playbackDetails, 'adsPlayingFlag', false);
    const { additionalProperties = {} } = playbackDetails[playableId] || {};
    const playbackTagId = additionalProperties?.tagId || '';
    const playbackTagConfig = playbackTagId ? tagDetails[playbackTagId] : null;
    // const mockPlaybackTagConfig = tagDetails['FREE_PLAYBACK_TAG_ID'];
    return {
        adsData,
        adsPlayingFlag,
        playbackControlMaster,
        showForwardButton: notify.showForwardButton,
        persistBufferOnFirstplay,
        playbackTagConfig,
        CP_CONTROL_CONFIG,
        contentDescriptorConfig,
        playbackSoftNudgeTimerList,
        // mockPlaybackTagConfig
    };
};

export default connect(mapStateToProps, {
    dispatchShowPreRollAds: getShowPreRollAds,
    dispatchGetAdsPlaying: adsPlaying,
    dispatchSdkAdsPlaying: setSdkAdsPlaying,
    dispatchShowPostRollAds: getShowPostRollAds,
    dispatchGetAdsPaused: getAdPausedStatus,
    dispatchToggleForwardButton: toggleForwardButton,
    updateKeyboardControlsActionDispatch: updateKeyboardControlsAction,
    showModalComponentActionDispatch: showModalComponentAction,
})(withDeviceUtil(ShakaPlayerComponent));
