import lodashCloneDeep from 'lodash/cloneDeep';
import lodashGet from 'lodash/get';


import React, { Component, useImperativeHandle, useRef } from 'react';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import {
    PLAYER_EVENTS_LIST,
    SLIDE_MENU_IDENTIFIERS,
    CONTROL_BAR_EVENTS,
    CENTER_ICONS,
} from '@airtel-tv/constants/PlayerConsts';
import {
    getFullScreen,
} from '@airtel-tv/utils/GlobalUtil';

import {
    checkFullScreen, exitFullScreen, screenOrientationToLandscape, screenOrientationToNeutral, addListenerToEvent, removeListenerFromEvent, checkIfPlayInPip, closePip,
} from '@airtel-tv/utils/WindowUtil';
import PlaybackStorageUtil from '@airtel-feature/playback/PlaybackStorageUtil';
import { isWeb } from '@airtel-feature/playback/util/PlaybackUtility';
import { LiveControlBarUIComponent } from '../../factories/PlaybackUiComponentFactory';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { connect } from 'react-redux';
import { focusSelector } from '../../../../tv/src/utilities/FocusManagementUtil';


class LiveControlBar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.deviceUtil = props.deviceUtil;

        const { onRef, onPlayerFunctionsNotFound } = this.props;

        onRef(this);

        this.isWeb = isWeb(), // true for web and false for TV

        this.playerEvents = null;

        this.childRef = React.createRef();
        //this.centerIconRef = React.createRef();
        //this.overlayDivRef = React.createRef();
        //this.optionLayerRef = React.createRef();

        this.volumeLev = React.createRef();

        this.onPlayerFunctionsNotFound = onPlayerFunctionsNotFound;
        // will be provided by player after init
        this.playerFunctions = null;

        this.defaultState = {
            enabled: false,
            playing: false,
            currentLiveTime: 0,
            playbackCurrentTime: 0,
            bufferedTime: 0,
            waiting: false,
            fullScreen: false,
            volumeLevel: 0,
            isMetaLoaded: false,
            centerIcon: null,
            meta: {
                playbackRate: 0,
                audioTracks: [],
                textTracks: [],
                qualityLevels: [],
            },
            videoEnded: false,
            showLiveButton: true,
            isMute: false,
            playercontrolBarVisible: true,
            stopAutoFullscreenLoop: false,
            isChannelGuideVisible: true,
        };

        this.state = lodashCloneDeep(this.defaultState);

        this.renditionFromLocalStorage = null;
        this.selectedPlaySpeed = null;
        this.selectedAudio = null;
        this.selectedCaption = null;

        addListenerToEvent('blur', this.pauseOnFocusLoss);
        addListenerToEvent('focus', this.fadeOut);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = {};

        const {
            onRef,
            onPlayerFunctionsNotFound,
            videoWrapperRef,
            getVideoTag,
        } = nextProps;

        if (
            onRef !== prevState.onRef
            || onPlayerFunctionsNotFound !== prevState.onPlayerFunctionsNotFound
            || videoWrapperRef !== prevState.videoWrapperRef
            || getVideoTag !== prevState.getVideoTag
        ) {
            changes = {
                onRef,
                onPlayerFunctionsNotFound,
                videoWrapperRef,
                getVideoTag,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    componentDidUpdate( prevProps,prevState) {
        const { fullScreen, stopAutoFullscreenLoop, enabled } = this.state;
        if(this.isWeb) {
            if(this.props.toggleFullScreenFlag !== prevProps.toggleFullScreenFlag) {
                this.toggleFullScreen();
            }
            else if(this.props.toggleFullScreenFlag && fullScreen === prevState.fullScreen && !fullScreen && !stopAutoFullscreenLoop && enabled) {
                this.toggleFullScreen();
                this.setState({
                    stopAutoFullscreenLoop: true,
                });
            }
            if (fullScreen !== prevState.fullScreen && !fullScreen) {
                const volumeLevel = PlaybackStorageUtil.getPlayerVolumeLevel();
                if (volumeLevel === 0) this.setState({ isMute: true });
                else this.setState({ isMute: false });
            }
        }
    }

    componentDidMount() {
        const { playingOnBanner } = this.props;
        if(this.isWeb) {//Fancode related Code not to be run for tv
            if (!playingOnBanner) {
                this.playerFunctions?.play();
                const volumeLevel = PlaybackStorageUtil.getPlayerVolumeLevel();
                if (volumeLevel === 0) {
                    this.playerFunctions?.volumeChange(0, false, false);
                    this.setState({ isMute: true });
                } else {
                    this.playerFunctions?.volumeChange(100, false, false);
                    this.setState({ isMute: false });
                } 
            }
        }
    }

    componentWillUnmount() {
        removeListenerFromEvent('blur', this.pauseOnFocusLoss);
        removeListenerFromEvent('focus', this.fadeOut);

        this.disposeEvents();
    }

    setFullScreenState = (value) => {
        this.setState({
            fullScreen: value,
        });
    };

    pauseOnFocusLoss = () => {
        // pause on tab change in mobile since user could have pressed home
        // but do not pause if user has gone to pip mode.
        if (this.deviceUtil.isMobile()
            && this.playerFunctions
            && !this.playerFunctions.paused()
            && !checkFullScreen()) {
            this.playerFunctions.pause();
        }
    };

    enableControlBar = () => {
        this.setState({
            enabled: true,
        });
    };

    setControlBarEventEmitter = (emitter) => {
        this.controlBarEventEmitter = emitter;
    }

    initPlayerVolume = () => {
        if (this.deviceUtil.isWeb()) {
            // volume Level
            this.playerFunctions.volumeChange(PlaybackStorageUtil.getPlayerVolumeLevel());
        }
    }

    setPlayerVolume = (event) => {
        if (event && event.data) {
            this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                { option: CENTER_ICONS.VOLUME });
        }
        const volumeLevel = this.playerFunctions ? this.playerFunctions.getVolumeLevel() : 0;
        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.UPDATE_VOLUME_BAR, volumeLevel);
        this.setState({
            volumeLevel,
        });
    }

    setPlayerQuality = () => {
        // uncomment when LIVE TV has video Renditions.
        this.renditionFromLocalStorage = PlaybackStorageUtil.getPlayerQuality();
        // quality Level
        // this.selectedRendition = PlaybackStorageUtil.getPlayerQuality();
        // if (this.selectedRendition) {
        //     this.changeBitrate(this.selectedRendition, true);
        // }
    }

    disableControlBar = () => {
        this.setState({
            enabled: false,
        });
    };

    setPlayerFunctions = (playerFunctions) => {
        this.playerFunctions = playerFunctions;
    };

    onPlay = () => {
        this.setState({
            playing: !this.playerFunctions.paused(),
            videoEnded: false,
        });
    }

    onPause = () => {
        this.setState({
            playing: !this.playerFunctions.paused(),
        });
    }

    onLoaded = (meta) => {
        this.setState({
            meta,
            waiting: false,
            playing: !this.playerFunctions.paused(),
            isMetaLoaded: true,
        });
    }

    onPlaying = () => {
        const { waiting, playing } = this.state;

        if (waiting !== false && playing === !this.playerFunctions.paused()) {
            this.setState({
                waiting: false,
                playing: !this.playerFunctions.paused(),
            });
        }
    }

    onTimeUpdate = () => {
        if (!this.playerFunctions) {
            return;
        }

        const { currentLiveTime } = this.state;
        const timeInSeconds = this.playerFunctions.getLiveCurrentTime();
        if (currentLiveTime !== timeInSeconds) {
            this.setState({
                currentLiveTime: timeInSeconds,
            });
        }
    }

    onSeekBarChange = (data) => {
        const { currentLiveTime } = this.state;
        if (currentLiveTime !== data.newTime) {
            this.setState({
                currentLiveTime: data.newTime,
            });
        }
    }

    onProgress = (bufferedTime) => {
        this.setState({
            bufferedTime: bufferedTime || 0,
        });
    }

    onWaiting = () => {
        this.setState({
            waiting: true,
            playing: !this.playerFunctions.paused(),
        });
    }

    onEnded = () => {
        this.setState({
            videoEnded: true,
        });
    }

    onCanPlayThrough = () => {
        const { waiting } = this.state;
        if (waiting) {
            this.setState({
                waiting: false,
            });
        }
    }

    disposeEvents = () => {
        if (this.playerEvents) {
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.PLAY, this.onPlay);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.PAUSE, this.onPause);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.LOADED, this.onLoaded);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, this.onCanPlayThrough);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.PLAYING, this.onPlaying);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE, this.onTimeUpdate);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, this.onSeekBarChange);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.PROGRESS, this.onProgress);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.WAITING, this.onWaiting);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.ENDEND, this.onEnded);
            this.playerEvents.emitter.off(PLAYER_EVENTS_LIST.VOLUME_CHANGE, this.setPlayerVolume);
            this.playerEvents = null;
        }
    }

    setPlayerEvents = (playerEvents) => {
        if (!playerEvents) {
            return;
        }

        this.disposeEvents();

        this.playerEvents = playerEvents;

        // subscribe events
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAY, this.onPlay);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PAUSE, this.onPause);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.LOADED, this.onLoaded);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAYING, this.onPlaying);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE, this.onTimeUpdate);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, this.onSeekBarChange);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PROGRESS, this.onProgress);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.WAITING, this.onWaiting);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ENDEND, this.onEnded);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, this.onCanPlayThrough);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.VOLUME_CHANGE, this.setPlayerVolume);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.OPEN_PIP, () => { this.setState({ isChannelGuideVisible: false }) });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.CLOSE_PIP, () => { this.setState({ isChannelGuideVisible: true }) });
    };

    play = () => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound({ event: PLAYER_EVENTS_LIST.PLAY });
            return;
        }

        this.playerFunctions.play();
    };

    pause = () => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound({ event: PLAYER_EVENTS_LIST.PAUSE });
            return;
        }

        this.playerFunctions.pause();
    };

    reset = () => {
        this.playerFunctions = null;
        this.setState(lodashCloneDeep(this.defaultState));
    }

    toggleFullScreen = (checkPip = true) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }


        const { videoWrapperRef, getVideoTag } = this.props;
        const fullScreenTargetElement = videoWrapperRef();

        const videoTag = getVideoTag();
        const browser = this.deviceUtil.getBrowserName();


        if (!checkFullScreen()) {
            this.setFullScreenState(true);
            getFullScreen(fullScreenTargetElement);
            screenOrientationToLandscape();//Present in web 
        }
        else {
            this.setFullScreenState(false);
            exitFullScreen(fullScreenTargetElement);
            screenOrientationToNeutral();
        }

        if (checkPip && checkIfPlayInPip(videoTag)) {
            closePip(browser, videoTag);
        }
    };

    toggleMute = () => { //only in web
        const { isMute } = this.state;
        if (isMute) {
            this.playerFunctions?.volumeChange(100, false, false);
            this.setState({ isMute: false });
        }
        else {
            this.playerFunctions?.volumeChange(0, false, false);
            this.setState({ isMute: true });
        } 
    }

    seekBarChange = (seekTime) => {
        const {
            enabled,
        } = this.state;

        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        const seekPosition = this.playerFunctions.getLiveCurrentTimeInSeconds() - (seekTime);

        this.setState({
            currentLiveTime: seekPosition,
        });

        this.playerFunctions.seekBarChange(seekPosition);
    };

    seekForward = (skipTime) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        this.playerFunctions.forwardLive(skipTime);
    };

    seekBackward = (skipTime) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        this.playerFunctions.backwardLive(skipTime);
    };

    replay = () => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }

        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        this.playerFunctions.replay();
    };

    handleOverlayClick = (e) => {
        const { playing } = this.state;
        if (e.target === e.currentTarget && !this.deviceUtil.isMobile()) {
            if (playing) {
                this.pause();
                this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                    { option: CENTER_ICONS.PAUSE });
            }
            else {
                this.play();
                this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                    { option: CENTER_ICONS.PLAY });
            }
        }
    };

    changeCaptions = (value) => {
        const { enabled } = this.state;
        if (!enabled) {
            return;
        }
        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound(); // TODO: add event name
            return;
        }

        this.playerFunctions.showCaptions(value);
    };


    onSlideMenuBack = ({ identifier }) => {
        if (identifier !== SLIDE_MENU_IDENTIFIERS.SETTINGS.id) {
            this.showSettings();
        }
    };

    setSeekBarVisibility = (event) => {
        this.setState({
            seekBarVisibility: event,
        });
    }

    onControlHover = (e, addLayer = true) => {
        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.UPDATE_SEEK_BAR_VISIBILITY, false);
        if (addLayer && this.childRef?.current?.optionLayerRef) {
            this.childRef?.current?.optionLayerRef?.classList.remove('d-none');
            this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.SETTINGS_OVERLAY_TOGGLE, true);
        }
        this.setState({
            showLiveButton: false,
        });
        if (e) {
            e.stopPropagation();
        }
    }

    onControlLeave = (e, removeLayer = true) => {
        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.UPDATE_SEEK_BAR_VISIBILITY, true);
        if (removeLayer && this.childRef?.current?.optionLayerRef) {
            this.childRef?.current?.optionLayerRef?.classList.add('d-none');
            this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.SETTINGS_OVERLAY_TOGGLE, false);
        }
        this.setState({
            showLiveButton: true,
        });
        if (e) {
            e.stopPropagation();
        }
    }

    touchControlBarOverlay = () => {
        // this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.SETTINGS_OVERLAY_TOGGLE, false);
        this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.HIDE_SETTINGS_MENU, true);
    }

    showCenterIcon(option) {
        if (!this.childRef || this.childRef.current || !this.childRef.current.centerIconRef) {
            return;
        }
        this.setState({
            centerIcon: option,
        });
        this.childRef.current.centerIconRef.classList.remove('d-none');
    }

    hideCenterIcon() {
        if (!this.childRef || this.childRef.current || !this.childRef.current.centerIconRef) {
            return;
        }
        this.setState({
            centerIcon: null,
        });
        this.childRef.current.centerIconRef.classList.add('d-none');
    }

    fadeIn() {
        if (!this.props || !this.props?.videoWrapperRef) {
            return;
        }
        // if (!this.childRef || !this.childRef.current || !this.childRef.current.overlayDivRef) {
        //     return;
        // }
        if (!this.deviceUtil.isMobile()) {
            this.setState({
                playercontrolBarVisible: true,
            });
        }
        const { videoWrapperRef } = this.props;
        this.childRef.current?.overlayDivRef?.classList?.remove('fade-out');
        this.childRef.current?.overlayDivRef?.classList?.add('fade-in');
        if(videoWrapperRef()) { // Found that for distro it was breaking
            videoWrapperRef().style.cursor = '';
        }

        if (this.playerCtaDivRef && this.playerCtaDivRef.current) {
            this.playerCtaDivRef.current?.classList?.add('cta-fade-in');
        }
    }

    fadeOut() {
        if (!this.props || !this.props?.videoWrapperRef) {
            return;
        }
        // if (!this.childRef || !this.childRef.current || !this.childRef.current.overlayDivRef) {
        //     return;
        // }
        const { videoWrapperRef } = this.props;
        if (!this.deviceUtil.isMobile()) {
            this.setState({
                playercontrolBarVisible: false,
            });
        }
        this.childRef?.current?.overlayDivRef?.classList?.remove('fade-in');
        this.childRef?.current?.overlayDivRef?.classList?.add('fade-out');
        videoWrapperRef().style.cursor = 'none';
        if(!this.isWeb) {
            this.childRef.current.overlayDivRef.classList.remove('canClosePlayer');
            focusSelector('#seekbar');
        }

        if (this.playerCtaDivRef && this.playerCtaDivRef.current) {
            this.playerCtaDivRef?.current?.classList?.remove('cta-fade-in');
        }
    }

    render() {
        return (
            <LiveControlBarUIComponent
                {...this.props}
                {...this.state}
                playerFunctions={this.playerFunctions}
                pause={this.pause}
                play={this.play}
                controlBarEventEmitter={this.controlBarEventEmitter}
                seekBackward={this.seekBackward}
                seekForward={this.seekForward}
                onPlayerFunctionsNotFound={this.onPlayerFunctionsNotFound}
                renditionFromLocalStorage={this.renditionFromLocalStorage}
                toggleMute={this.toggleMute}
                seekBarChange={this.seekBarChange}
                toggleFullScreen={this.toggleFullScreen}
                setFullScreenState={this.setFullScreenState}
                onControlHover={this.onControlHover}
                onControlLeave={this.onControlLeave}
                handleOverlayClick={this.handleOverlayClick}
                touchControlBarOverlay={this.touchControlBarOverlay}
                LANGUAGE={this.LANGUAGE}
                ref={this.childRef}
            />)
    }


}

//export default withDeviceUtil(LiveControlBar);

const mapStateToProps = (state) => {
    const {
        playbackDetails,
        notify: { 
            showPlayback,
            playingOnBanner
        }
    } = state;
    const adsPlayingFlag = lodashGet(playbackDetails, 'adsPlayingFlag', null);
    const toggleFullScreenFlag = lodashGet(playbackDetails, 'toggleFullScreenFlag', false);
    // const preRollAdsPlaying = lodashGet(playbackDetails, 'preRollAdsPlaying', null);
    return {
        adsPlayingFlag,
        showPlayback,
        playingOnBanner,
        toggleFullScreenFlag,
    };
};
export default connect(mapStateToProps, null)(withDeviceUtil(LiveControlBar));
