import { LocationUtil } from '@airtel-tv/utils';
import { PLAYBACK_TYPES } from '@airtel-tv/constants/PlayerConsts';
import { INVALID_URL, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { INVALID_PLAYBACK_URL } from '@airtel-tv/constants/PlaybackConsts';
import { getSeriesDetailContainerVideoMeta, getVodDetailContainerVideoMeta } from '../helpers/videoMetaHelper';


export default class PlaybackUtil {
    static getPlaybackExtension(url) {
        const urlSchema = LocationUtil.parseUrl(url);
        if (urlSchema === INVALID_URL) {
            return INVALID_PLAYBACK_URL;
        }
        const path = urlSchema.pathname;
        if (path) {
            const splitted = path.split('.');
            const playbackExtension = splitted[splitted.length - 1].toUpperCase();
            if (PLAYBACK_TYPES[playbackExtension]) {
                return PLAYBACK_TYPES[playbackExtension];
            }

            // custom url schema for a partner
            const customPlaybackExt = urlSchema.pathname.split(/\((.+)\)/)[1];
            try {
                const customPlaybackType = customPlaybackExt.split(',')[0].split('=')[1];
                return PLAYBACK_TYPES[customPlaybackType.toUpperCase()] || '';
            }
            catch (e) {
                console.error('No valid type found', customPlaybackExt);
            }
        }

        return '';
    }

    static playbackVideoMeta(playbackContentDetails) {
        const { programType, channelId } = playbackContentDetails;
        switch (programType) {
            case PROGRAM_TYPES.EPISODE:
                return getSeriesDetailContainerVideoMeta(playbackContentDetails);

            case PROGRAM_TYPES.LIVETVCHANNEL:
                return getVodDetailContainerVideoMeta(playbackContentDetails);
            default:
                return {};
        }
    }
}
