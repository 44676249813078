import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { contentDetailsFetchAction, emptyDetailAction } from '@airtel-feature/content-details/content-details/actions/ContentDetailsAction';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import PlayerContainer from '@airtel-feature/content-details/content-detail-page/container/PlayerContainer';
import { getSourceName, getSourceNameFromStorage, setAnalyticsMeta } from '@airtel-tv/utils/GlobalUtil';
import { triggerLogin } from '@airtel-feature/auth/AuthAction';
import {
    CONTENT_PROVIDERS, CUSTOMER_TYPE, DEEPLINK_INGRESS_KEYS, MODAL_POPUP, PROGRAM_TYPES,
} from '@airtel-tv/constants';
import { DeviceTypeUtil, checkWindowExist, withDeviceUtil } from '@airtel-tv/utils';
import { hideModalComponentAction, showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import { contentVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { LOGIN_SOURCE } from '@airtel-tv/constants/EventsConst';
import { handleEpgViewChange } from '@airtel-feature/notify/NotifyActions';
import { fetchAllChannels } from '@airtel-feature/layout/actions/ChannelAction';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { toggleFullScreen } from './actions/PlaybackActions';
import { showNonAirtelUserAction } from '../../tv/src/modules/user/UserAction';

function DirectPlaybackContainer(props) {
    const {
        contentDetails,
        isAuthenticated,
        showNonAirtelUserActionDispatch,
        customerType,
        dispatchToggleFullScreen,
        triggerLoginDispatch,
        showModalComponentActionDispatch,
        hideModalComponentActionDispatch,
        playbackData,
        history,
        hasPrevPath,
        match: { params = {} } = {},
        navigate,
        location: { pathname: currentPath = '' },
        handleEpgViewChangeDispatch,
        fetchAllChannelsDispatch,
        deviceUtil,
        maxAllowedPlaybackTime,
        userContentProperties,
        location,
        isXppUser,
        deepLinkConfig,
    } = props;

    const { contentId, contentTitle } = params;

    const eventFired = useRef(false);

    const [
        canPlayPlayback,
        setCanPlayPlayback,
    ] = useState(false);

    useEffect(() => {
        if (!deviceUtil.isScopedWebview()) {
            setCanPlayPlayback(true);
        }
    }, []);

    const getUserSessionId = () => {
        const { userSessionId } = setAnalyticsMeta(contentId);
        return userSessionId;
    };
    const navigateBack = () => {
        if (checkWindowExist() && hasPrevPath) {
            history?.back();
        }
        else {
            navigate(ROUTE_PATHS.ROOT);
        }
    };
    const { hash } = location;
    useEffect(() => {
        if (hash && (hash.includes('isEpgPageVisible'))) {
            handleEpgViewChangeDispatch({ isEpgPageVisible: true });
        }
        else {
            handleEpgViewChangeDispatch({ isEpgPageVisible: false });
        }
    }, [
        location.hash,
    ]);
    useEffect(() => {
        const { contentDetailsFetchDispatch, emptyDetailActionDipatch } = props;
        if (contentId) {
            contentDetailsFetchDispatch(contentId);
        }
        getUserSessionId();
        return () => {
            emptyDetailActionDipatch();
        };
    }, [
        contentId,
    ]);

    useEffect(() => {
        if (!isAuthenticated) {
            navigateBack();
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.FULLSCREEN_PLAYBACK,
                redirectUrl: currentPath,
            });
        }
        else if (customerType === CUSTOMER_TYPE.NON_AIRTEL) {
            if (showNonAirtelUserActionDispatch && typeof showNonAirtelUserActionDispatch === 'function') {
                navigateBack();
                if (DeviceTypeUtil?.isWeb()) {
                    showNonAirtelUserActionDispatch();
                }
                else {
                    showModalComponentActionDispatch({
                        showModalValue: true,
                        componentNameValue: MODAL_POPUP.AIRTEL_CONNECTION_POPUP,
                        overrideCrossButtonValue: false,
                        payload: { buyNewConnection: false },
                    });
                }
            }
        }
    }, [
        isAuthenticated,
        customerType,
    ]);

    useEffect(() => {
        if (playbackData && DeviceTypeUtil?.isWeb()) {
            dispatchToggleFullScreen({ toggleFullScreenFlag: true });
        }
    }, [
        playbackData,
    ]);

    useEffect(() => {
        const contentDetail = contentDetails?.[contentId];
        if (contentDetail?.programType && [
            PROGRAM_TYPES.LIVETVCHANNEL,
        ].includes(contentDetail.programType)) {
            if (!ScopedWebviewUtil.canPlayPlayback(maxAllowedPlaybackTime, userContentProperties, contentDetails)) {
                showModalComponentActionDispatch({
                    showModalValue: true,
                    componentNameValue: MODAL_POPUP.DOWNLOAD_XSTREAM_APP_POPUP,
                    overrideCrossButtonValue: true,
                    payload: {
                        contentDetails: contentDetail || {},
                        isSubscribed: isXppUser,
                        pageId: getSourceName(location.pathname),
                        meta: {
                            popup_id: 'download_hard_popup',
                            source_name: getSourceName(location.pathname),
                            content_category: 'Content',
                        },
                        notNowBtnHandler: () => {
                            hideModalComponentActionDispatch({
                                showModalValue: false,
                                componentNameValue: null,
                                overrideCrossButtonValue: false,
                            });
                            history.go(-1);
                        },
                        downloadNowBtnHandler: () => {
                            ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, DEEPLINK_INGRESS_KEYS.HARD_POPUP);
                        },
                    },
                });
                return;
            }
            setCanPlayPlayback(true);
        }
        if (contentDetail && !contentDetail?.fetching && !eventFired.current) {
            if (contentDetail?.cpId === CONTENT_PROVIDERS.DISTROTV) {
                fetchAllChannelsDispatch();
            }
            const { currentSource, prevSource } = getSourceNameFromStorage() || {};

            const meta = {
                source_name: currentSource,
                source_page: prevSource,
                cp_name: contentDetails?.cpId,
                content_name: contentTitle,
                channel_name: contentTitle,
                channel_id: contentId,
                content_id: contentId,
            };
            contentVisibleEvent(meta);
            eventFired.current = true;
        }
    }, [
        contentId,
        contentDetails,
        contentTitle,
    ]);

    return (
        <div className="direct-playback-container sdk-player">
            {canPlayPlayback && contentDetails[contentId] && customerType !== CUSTOMER_TYPE.NON_AIRTEL ? (
                <PlayerContainer
                    contentDetails={contentDetails[contentId]}
                    playableId={contentId}
                />
            ) : null}
        </div>
    );
}

const mapStateToProps = (state, props) => {
    const {
        userConfig: {
            customerType = '',
            userContentProperties,
            userContentProperties: { oSeg, ut },
        } = {},
        contentDetails,
        authConfig: {
            isAuthenticated = false,
        } = {},
        playbackDetails = {},
        location: { navigationObj: { prevPath = '' } = {} } = {},
        appConfig,
    } = state;
    const isXppUser = !!(oSeg || '').split(',').filter(item => item === 'xstreampremium_paid' || item === 'xstreampremium_telco_paid').length;

    const { match: { params = {} } } = props;
    const { contentId } = params;
    const playbackData = playbackDetails[contentId];
    const { maxAllowedPlaybackTime = 20, deepLinkConfig } = appConfig;

    return {
        contentDetails,
        isAuthenticated,
        customerType,
        playbackData,
        hasPrevPath: prevPath,
        maxAllowedPlaybackTime,
        userContentProperties,
        isXppUser,
        deepLinkConfig,
    };
};

export default withRouter(connect(mapStateToProps, {
    contentDetailsFetchDispatch: contentDetailsFetchAction,
    triggerLoginDispatch: triggerLogin,
    dispatchToggleFullScreen: toggleFullScreen,
    showNonAirtelUserActionDispatch: showNonAirtelUserAction,
    showModalComponentActionDispatch: showModalComponentAction,
    emptyDetailActionDipatch: emptyDetailAction,
    handleEpgViewChangeDispatch: handleEpgViewChange,
    fetchAllChannelsDispatch: fetchAllChannels,
    hideModalComponentActionDispatch: hideModalComponentAction,
})(withDeviceUtil(DirectPlaybackContainer)));
